import { IPrice } from 'types/price';
import { LessonType } from './courses';

export const PRICE_LIST: IPrice[] = [
  {
    currencyCode: 'BYN',
    countries: ['BY'],
    packages: [
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 60,
        prices: [55, 100, 140],
      },
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 120,
        prices: [82, 150, 210],
      },
      {
        lessonType: LessonType.INDIVIDUAL,
        lessonDurationMin: 60,
        prices: [82, 150, 210],
      },
    ],
  },
  {
    currencyCode: 'RUB',
    countries: [
      'RU',
      'UA',
      'KZ',
      'TR',
      'TM',
      'UZ',
      'AZ',
      'KG',
      'TJ',
      'AF',
      'PK',
      'GE',
      'AM',
    ],
    packages: [
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 60,
        prices: [1940, 3730, 5170],
      },
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 120,
        prices: [2910, 5600, 7800],
      },
      {
        lessonType: LessonType.INDIVIDUAL,
        lessonDurationMin: 60,
        prices: [2910, 5600, 7700],
      },
    ],
  },
  {
    currencyCode: 'EUR',
    countries: [
      'GR', // Греция
      'CY', // Кипр
      'LV', // Латвия
      'LT', // Литва
      'MT', // Мальта
      'PL', // Польша
      'PT', // Португалия
      'SK', // Словакия
      'SI', // Словения
      'HR', // Хорватия
      'CZ', // Чехия
      'EE', // Эстония
      'ME', // Черногория
      'MD', // Молдавия
    ],
    packages: [
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 60,
        prices: [40, 75, 105],
      },
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 120,
        prices: [60, 113, 157],
      },
      {
        lessonType: LessonType.INDIVIDUAL,
        lessonDurationMin: 60,
        prices: [60, 113, 155],
      },
    ],
  },
  {
    currencyCode: 'EUR',
    countries: [
      'ES',
      'IT',
      'DE',
      'FR',
      'AT',
      'AD',
      'GB',
      'CH',
      'SE',
      'DK',
      'NO',
      'FI',
      'IS',
      'IE',
      'BE',
      'NL',
      'LI',
      'LU',
    ],
    packages: [
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 60,
        prices: [60, 113, 157],
      },
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 120,
        prices: [90, 170, 235],
      },
      {
        lessonType: LessonType.INDIVIDUAL,
        lessonDurationMin: 60,
        prices: [90, 170, 235],
      },
    ],
  },
  {
    currencyCode: 'USD',
    countries: [
      'US',
      'CA',
      'AU',
      'NZ',
      'KR',
      'IL',
      'JP',
      'HK',
      'MO',
      'QA',
      'AE',
      'SG',
    ],
    packages: [
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 60,
        prices: [60, 113, 157],
      },
      {
        lessonType: LessonType.GROUP,
        lessonDurationMin: 120,
        prices: [90, 170, 235],
      },
      {
        lessonType: LessonType.INDIVIDUAL,
        lessonDurationMin: 60,
        prices: [90, 170, 235],
      },
    ],
  },
];

export const DEFAULT_PRICE: IPrice = {
  currencyCode: 'USD',
  countries: [],
  packages: [
    {
      lessonType: LessonType.GROUP,
      lessonDurationMin: 60,
      prices: [40, 75, 105],
    },
    {
      lessonType: LessonType.GROUP,
      lessonDurationMin: 120,
      prices: [60, 113, 157],
    },
    {
      lessonType: LessonType.INDIVIDUAL,
      lessonDurationMin: 60,
      prices: [60, 113, 155],
    },
  ],
};
