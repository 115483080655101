import React from 'react';
import cx from 'classnames';
import loadable from '@loadable/component';
import Spinner from 'react-bootstrap/Spinner';

const WidgetPopup = loadable(() => import('./WidgetPopup'));

export const ContactWidget = () => {
  const [opened, setOpened] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const handleClick = React.useCallback(() => {
    setOpened(!opened);
    if (!opened) {
      setLoaded(true);
    }
  }, [opened]);

  const handleClose = React.useCallback(() => {
    setOpened(false);
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 10000);
  }, []);

  return (
    <div className="contact-widget">
      {loaded && (
        <WidgetPopup
          opened={opened}
          onClose={handleClose}
          fallback={
            opened ? (
              <div className="widget-popup opened" style={{ height: 400 }}>
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  variant="warning"
                  aria-hidden="true"
                />
                <span className="sr-only">Загрузка...</span>
              </div>
            ) : (
              <div />
            )
          }
        />
      )}
      <button
        onClick={handleClick}
        className={cx('widget-btn', opened && 'active')}
      >
        <svg
          className="open-icon"
          width="35"
          height="32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 35 32"
        >
          <path
            d="M11.267 12.698h12.1m-12.1 3.774h12.1M4.81 23.578C2.431 21.19 1 18.122 1 14.774 1 7.167 8.387 1 17.5 1S34 7.167 34 14.774c0 7.606-7.387 13.773-16.5 13.773-1.872 0-3.671-.26-5.349-.74L12 27.793 5.033 31v-7.178l-.223-.244z"
            stroke="#fff"
          />
        </svg>

        <svg
          className="close-icon"
          width="16"
          height="16"
          viewBox="0 0 23 23"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fillRule="evenodd">
            <path d="M0 1.414L1.415 0l21.213 21.213-1.414 1.414z" />
            <path d="M21.213 0l1.414 1.415L1.414 22.628 0 21.214z" />
          </g>
        </svg>
      </button>
    </div>
  );
};
