import React from 'react';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { ContactWidget } from './ContactWidget';
import { SidebarContext } from './Sidebar/SidebarContext';
import { ErrorBoundary } from '../System/ErrorBoundrary';

interface IProps {
  element: React.ReactNode;
}

export const Layout = ({ element }: IProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const [fixed, setFixed] = React.useState(false);

  return (
    <SidebarContext.Provider
      value={{
        expanded,
        fixed,
        setExpanded,
        setFixed,
      }}
    >
      <ErrorBoundary>
        <Header />
        <Sidebar />
        {element}
        <Footer />
        <ContactWidget />
      </ErrorBoundary>
    </SidebarContext.Provider>
  );
};
