import React from 'react';
import styled from '@emotion/styled';

interface IProps {
  children: React.ReactNode;
  timeout?: number;
  transition?: number;
  className?: string;
}

interface ICSSProps {
  shown: boolean;
  transition: number;
}

const Overlay = styled.div<ICSSProps>`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  transition: opacity ${({ transition }) => transition}s;
`;

export const DynamicContentOverlay = ({
  children,
  timeout,
  transition,
  className,
}: IProps) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    if (!timeout) {
      setLoaded(true);
    }
    setTimeout(() => {
      setLoaded(true);
    }, timeout);
  }, []);

  return (
    <Overlay className={className} shown={loaded} transition={transition || 1}>
      {children}
    </Overlay>
  );
};
