import React from 'react';
import { Link } from 'gatsby';
import { routes } from 'routes';
import { CONTRACT_OFFER_LINK } from 'constants/contacts';

export const NavLinks = () => (
  <ul className="footer-nav-links">
    <li>
      <Link className="nav-link" to={routes.reviews.path}>
        Отзывы
      </Link>
    </li>
    <li>
      <Link className="nav-link" to={routes.promotions.path}>
        Акции
      </Link>
    </li>
    <li>
      <Link className="nav-link" to={routes.contacts.path}>
        Контакты
      </Link>
    </li>
    <li>
      <Link className="nav-link" to={routes.payments.path}>
        Способы оплаты
      </Link>
    </li>
    <li>
      <a
        href={CONTRACT_OFFER_LINK}
        target="_blank"
        className="nav-link"
        rel="noreferrer"
      >
        Договор оферты
      </a>
    </li>
  </ul>
);
