import React from 'react';
import { ErrorModal } from 'containers/Modals/ErrorModal';
import { REFRESH_HASH } from 'containers/Modals/ErrorModal/constants';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  static getDerivedStateFromError(error: Error) {
    if (!location.hash.includes(REFRESH_HASH)) {
      switch (error.name) {
        case 'ChunkLoadError': {
          return { hasError: true };
        }
        case 'SyntaxError': {
          if (error.message === "Unexpected token '<'") {
            return { hasError: true };
          }
          break;
        }
        // case 'Error': {
        //   if (
        //     new RegExp(
        //       /^page resources for .*? not found\. Not rendering React$/,
        //     ).test(error.message)
        //   ) {
        //     return { hasError: true };
        //   }
        //   break;
        // }
      }
    }
  }

  render() {
    return (
      <>
        {this.props.children}
        {this.state.hasError && <ErrorModal />}
      </>
    );
  }
}
