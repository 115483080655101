import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import './styles.scss';
import Button from 'react-bootstrap/Button';
import { REFRESH_HASH } from './constants';

export const ErrorModal = () => {
  const [timer, setTimer] = React.useState(5);
  const triggerReload = React.useCallback(() => {
    location.href = location.href + REFRESH_HASH;
    location.reload();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer((timer) => {
        const nextTimer = timer - 1;
        if (nextTimer === 0) {
          triggerReload();
        }
        return nextTimer;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <Modal
      show
      centered
      className="error-modal"
      onHide={() => {
        return false;
      }}
    >
      <Modal.Body className="text-center">
        <h4 className="title">Новая версия вебсайта была выпущена</h4>
        <div className="subtitle">
          Страница будет перезагружена автоматически через:
        </div>
        <div className="timer">
          {timer >= 0 ? timer : <Spinner animation="border" />}
        </div>
        {timer < 0 && (
          <Button onClick={triggerReload} className="action-btn" variant="link">
            Перезагрузить страницу
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};
