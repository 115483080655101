import React from 'react';
import { Helmet } from 'react-helmet';
import fontMullerBlack from 'assets/fonts/Muller/Muller-Black/MullerBlack.woff2';
import fontMullerRegular from 'assets/fonts/Muller/Muller-Regular/MullerRegular.woff2';
import { LocationProvider } from 'containers/Location/LocationProvider';
import { CurrencyProvider } from 'containers/Currency/CurrencyProvider';
import 'containers/System';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'styles/global.scss';
import 'focus-visible';

interface IProps {
  element: Element;
}

const Root = ({ element }: IProps) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://www.google-analytics.com" />
      <link rel="preconnect" href="https://mc.yandex.ru" />
      <link rel="preconnect" href="https://pro.ip-api.com" />
      <link
        rel="preload"
        as="font"
        href={fontMullerBlack}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={fontMullerRegular}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
    <LocationProvider>
      <CurrencyProvider>{element}</CurrencyProvider>
    </LocationProvider>
  </>
);

export { Root };
