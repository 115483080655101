const path = require('path');
const routes = {
  home: {
    path: '/',
    component: path.resolve(__dirname, 'screens/Home/index.tsx'),
  },
  programming: {
    path: '/programming/',
    component: path.resolve(__dirname, 'screens/Programming/index.tsx'),
  },
  promotions: {
    path: '/promotions/',
    component: path.resolve(__dirname, 'screens/Promotions/index.tsx'),
  },
  trialLesson: {
    path: '/trial-lesson/',
    component: path.resolve(__dirname, 'screens/TrialLesson/index.tsx'),
  },
  reviews: {
    path: '/reviews/',
    component: path.resolve(__dirname, 'screens/Reviews/index.tsx'),
  },
  contacts: {
    path: '/contacts/',
    component: path.resolve(__dirname, 'screens/Contacts/index.tsx'),
  },
  payments: {
    path: '/payments/',
    component: path.resolve(__dirname, 'screens/Payments/index.tsx'),
  },
  programmingPay: {
    path: '/programming-pay/',
    component: path.resolve(__dirname, 'screens/ProgrammingPay/index.tsx'),
  },
  notFound: {
    path: '404.html',
    component: path.resolve(__dirname, 'screens/404/index.tsx'),
  },
};

/**
 * @type {Object.<string, IRoute>}
 */
const paths = Object.keys(routes).reduce((acc, route) => {
  acc[route] = routes[route].path;
  return acc;
}, {});

module.exports = {
  routes,
  paths,
};
