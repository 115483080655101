import { IEnvironment } from './types';

export const ENVIRONMENT_STORAGE = 'environment';

export enum EnvironmentIds {
  PRODUCTION = 'production',
  LOCAL = 'local',
}

export const environments: { [key in EnvironmentIds]: IEnvironment } = {
  [EnvironmentIds.PRODUCTION]: {
    apiPath: 'https://api.up-school.online',
  },
  [EnvironmentIds.LOCAL]: {
    apiPath: 'http://localhost:8000',
  },
};
