// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-screens-404-index-tsx": () => import("./../../../src/screens/404/index.tsx" /* webpackChunkName: "component---src-screens-404-index-tsx" */),
  "component---src-screens-contacts-index-tsx": () => import("./../../../src/screens/Contacts/index.tsx" /* webpackChunkName: "component---src-screens-contacts-index-tsx" */),
  "component---src-screens-home-index-tsx": () => import("./../../../src/screens/Home/index.tsx" /* webpackChunkName: "component---src-screens-home-index-tsx" */),
  "component---src-screens-payments-index-tsx": () => import("./../../../src/screens/Payments/index.tsx" /* webpackChunkName: "component---src-screens-payments-index-tsx" */),
  "component---src-screens-programming-index-tsx": () => import("./../../../src/screens/Programming/index.tsx" /* webpackChunkName: "component---src-screens-programming-index-tsx" */),
  "component---src-screens-programming-pay-index-tsx": () => import("./../../../src/screens/ProgrammingPay/index.tsx" /* webpackChunkName: "component---src-screens-programming-pay-index-tsx" */),
  "component---src-screens-promotions-index-tsx": () => import("./../../../src/screens/Promotions/index.tsx" /* webpackChunkName: "component---src-screens-promotions-index-tsx" */),
  "component---src-screens-reviews-index-tsx": () => import("./../../../src/screens/Reviews/index.tsx" /* webpackChunkName: "component---src-screens-reviews-index-tsx" */),
  "component---src-screens-trial-lesson-index-tsx": () => import("./../../../src/screens/TrialLesson/index.tsx" /* webpackChunkName: "component---src-screens-trial-lesson-index-tsx" */)
}

