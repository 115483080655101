import React from 'react';
import { LocationContext } from 'containers/Location/LocationContext';
import { DEFAULT_PHONE_NUMBER, PHONE_NUMBERS } from 'constants/contacts';
import { IPhone } from 'types/phone';

let phone: IPhone;

export const usePhone = (): IPhone => {
  const location = React.useContext(LocationContext);
  if (phone) {
    return phone;
  }

  if (location.isReal) {
    const foundPhone = PHONE_NUMBERS[location.countryCode.toLowerCase()];
    if (foundPhone) {
      phone = foundPhone;
      return foundPhone;
    }
  }

  return DEFAULT_PHONE_NUMBER;
};
