import React from 'react';
import { usePhone } from 'utils/hooks/usePhone';
import { DynamicContentOverlay } from 'components/DynamicContentOverlay';
import { MESSENGER_LINKS } from 'constants/contacts';
import viberImg from 'assets/images/socials/viber.svg';
import telegramImg from 'assets/images/socials/telegram.svg';

export const DesktopHeader = () => {
  const phone = usePhone();
  return (
    <DynamicContentOverlay className="desktop-header container">
      <div className="desktop-header-content">
        <div className="header-text">Есть вопросы? Звоните</div>
        <div className="header-links">
          <a href={MESSENGER_LINKS.viber} className="messenger-link">
            <img src={viberImg} alt="Viber" />
          </a>
          <a
            href={MESSENGER_LINKS.telegram}
            target="_blank"
            rel="noreferrer"
            className="messenger-link"
          >
            <img src={telegramImg} alt="Telegram" />
          </a>
          <a href={phone.href} className="phone-link">
            {phone.formattedNumber}
          </a>
        </div>
      </div>
    </DynamicContentOverlay>
  );
};
