import React from 'react';
import { usePhone } from 'utils/hooks/usePhone';
import { DynamicContentOverlay } from 'components/DynamicContentOverlay';
import mailImg from './images/mail.svg';
import phoneImg from './images/phone.svg';
import { SOCIAL_LINKS } from 'constants/contacts';
import facebookImg from 'assets/images/socials/facebook.svg';
import instagramImg from 'assets/images/socials/instagram.svg';

export const Contacts = () => {
  const phone = usePhone();

  return (
    <div className="footer-contacts">
      <div className="social-links">
        <a
          href={SOCIAL_LINKS.facebook}
          className="social-link"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={facebookImg} alt="Facebook логотип" />
        </a>
        <a
          href={SOCIAL_LINKS.instagram}
          className="social-link"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={instagramImg} alt="Instagram логотип" />
        </a>
      </div>
      <DynamicContentOverlay>
        <a href={phone.href} className="phone-link">
          <img src={phoneImg} alt="Телефон" />
          <span>{phone.formattedNumber}</span>
        </a>
      </DynamicContentOverlay>
      <a href="mailto:info@up-school.online" className="email-link">
        <img src={mailImg} alt="Email" />
        <span>info@up-school.online</span>
      </a>
    </div>
  );
};
