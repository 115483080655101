import React from 'react';

export const SidebarContext = React.createContext<{
  expanded: boolean;
  fixed: boolean;
  setExpanded: (state: boolean) => void;
  setFixed: (state: boolean) => void;
}>({
  expanded: false,
  fixed: false,
  setExpanded: () => null,
  setFixed: () => null,
});
