import React from 'react';
import { isDevelopment } from 'utils/isDevelopment';
import { LocationContext } from './LocationContext';
import { defaultLocation } from './constants';
import { fetchMainGeoLocation, fetchSupportLocation } from './api';
import { ILocation } from './types';

interface IProps {
  children: React.ReactNode;
}

export const LocationProvider = ({ children }: IProps) => {
  const [locationState, setLocation] = React.useState<{
    loading: boolean;
    location: ILocation;
  }>({
    loading: true,
    location: { ...defaultLocation },
  });

  React.useEffect(() => {
    import('nprogress')
      .then(async (NProgress) => {
        NProgress.set(0.4);
        let location;
        if (!isDevelopment) {
          location = await fetchMainGeoLocation();
          if (!location) {
            location = await fetchSupportLocation();
          }
        }

        setLocation({
          loading: false,
          location: location || locationState.location,
        });

        NProgress.done();
      })
      .catch(() => {
        setLocation({ loading: false, location: locationState.location });
        // console.error(e);
      });
  }, []);

  return (
    <LocationContext.Provider value={locationState.location}>
      {children}
    </LocationContext.Provider>
  );
};
