import React, { SyntheticEvent } from 'react';
import arrowImg from './images/arrow_right.png';
import cx from 'classnames';

interface IProps {
  active: boolean;
  onClick: () => void;
}

export const Hamburger = ({ active, onClick }: IProps) => {
  const onMouseOver = React.useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <button
      className={cx('hamburger', { active: active })}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
    >
      <img className="hamburger-img" src={arrowImg} alt="стрелка" />
    </button>
  );
};
