import axios from 'axios';
import { ILocation } from './types';

const currencyCodeMap: { [key: string]: string } = {
  BYR: 'BYN',
};

const axiosInstance = axios.create({
  timeout: 2000,
});

export const fetchMainGeoLocation = async (): Promise<ILocation | null> => {
  try {
    const response = await axiosInstance.get(
      'https://pro.ip-api.com/json/?fields=8388883&key=67ZV4nvrkXf2b7a&lang=ru',
    );

    return {
      countryCode: response.data.countryCode,
      country: response.data.country,
      city: response.data.city,
      currencyCode:
        currencyCodeMap[response.data.currency] || response.data.currency,
      timezone: response.data.timezone,
      isReal: true,
    };
  } catch (e) {
    // console.err(e)
    return null;
  }
};

export const fetchSupportLocation = async (): Promise<ILocation | null> => {
  try {
    const response = await axiosInstance.get(
      'https://api.ipdata.co/ru?api-key=ecc06c1985f3032466053b14dae125062a15ab650baac114febea2bb',
    );

    return {
      countryCode: response.data.country_code,
      country: response.data.country_name,
      city: response.data.city,
      currencyCode: response.data.currency.code,
      timezone: response.data.time_zone.name,
      isReal: true,
    };
  } catch (e) {
    // console.err(e)
    return null;
  }
};
