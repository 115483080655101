import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Logo } from './Logo';
import fireIcon from './images/fire.inline.svg';
import universityIcon from './images/university.inline.svg';
import emailIcon from './images/email.inline.svg';
import saleIcon from './images/sale.inline.svg';
import { Divider } from './Divider';
import { NavLink } from './NavLink';
import { Hamburger } from './Hamburger';
import { NavContacts } from './NavContacts';
import { SidebarContext } from './SidebarContext';
// import { SocialLinks } from './SocialLinks';
import throttle from 'lodash.throttle';
import { routes } from 'routes';

export const Sidebar = () => {
  const [frozen, setFrozen] = React.useState(false);
  const { expanded, fixed, setExpanded, setFixed } =
    React.useContext(SidebarContext);

  const handleSetExpanded = React.useCallback(
    throttle(
      (v) => {
        setExpanded(v);
      },
      300,
      { trailing: false },
    ),
    [],
  );

  const onHamburger = React.useCallback(() => {
    setExpanded(!expanded);
    setFixed(!expanded);
  }, [expanded]);

  const onMouseOver = React.useCallback(() => {
    if (expanded || frozen) {
      return null;
    }
    handleSetExpanded(true);
  }, [frozen, expanded]);

  const onMouseLeave = React.useCallback(() => {
    if (!fixed) {
      setExpanded(false);
    }
  }, [fixed]);

  const onLinkClick = React.useCallback(() => {
    setFrozen(true);
    setExpanded(false);
    setTimeout(() => {
      setFrozen(false);
    }, 700);
  }, []);

  return (
    <CSSTransition appear in={expanded} timeout={200}>
      <aside
        onMouseOver={onMouseOver}
        onFocus={onMouseOver}
        onMouseLeave={onMouseLeave}
        className="sidebar"
      >
        <Hamburger active={expanded} onClick={onHamburger} />
        <PerfectScrollbar
          className="sidebar-inner"
          options={{ suppressScrollX: true }}
        >
          <Logo />
          {/*<SocialLinks />*/}
          <ul className="nav-links">
            <Divider />
            <NavLink
              icon={fireIcon}
              path={routes.trialLesson.path}
              title="Попробовать бесплатно"
              className="trial"
              onClick={onLinkClick}
            />
            <NavLink
              icon={universityIcon}
              title="Курсы"
              className="courses"
              path={routes.home.path}
              nestedLinks={[
                {
                  path: routes.programming.path,
                  title: 'Программирование',
                  pathType: 'local',
                },
                {
                  path: 'https://chess-up.school',
                  title: 'Шахматы',
                  pathType: 'external',
                },
              ]}
              onClick={onLinkClick}
            />
            <NavLink
              path={routes.promotions.path}
              icon={saleIcon}
              title="Акции"
              className="promotions"
              onClick={onLinkClick}
            />
            <NavLink
              path={routes.contacts.path}
              icon={emailIcon}
              title="Контакты"
              className="contacts"
              onClick={onLinkClick}
            />
            <Divider />
          </ul>
          <NavContacts />
        </PerfectScrollbar>
      </aside>
    </CSSTransition>
  );
};
