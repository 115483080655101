import axios from 'axios';
import { ICurrencyRate } from 'types/currency';

export const fetchCurrencyRate = async (
  baseCurrency: string,
  targetCurrency: string,
): Promise<ICurrencyRate | null> => {
  try {
    const response = await axios.get(
      `https://api.exchangerate.host/convert?from=${baseCurrency}&to=${targetCurrency}`,
    );

    return response.data.info.rate;
  } catch (e) {
    // console.err(e)
    return null;
  }
};
