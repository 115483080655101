import { isSsr } from 'utils/isSsr';
import { isDevelopment } from 'utils/isDevelopment';
import { IEnvironment } from './types';
import { ENVIRONMENT_STORAGE, EnvironmentIds, environments } from './constants';

export let environment: IEnvironment = isDevelopment
  ? environments[EnvironmentIds.LOCAL]
  : environments[EnvironmentIds.PRODUCTION];

export const setEnvironment = (id: EnvironmentIds) => {
  if (!Object.values(EnvironmentIds).includes(id)) {
    console.log('wrong id');
  }

  localStorage.setItem(ENVIRONMENT_STORAGE, id);
  environment = environments[id];
};

(function () {
  if (!isSsr && isDevelopment) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.setEnvironment = setEnvironment;
    const id = localStorage.getItem(ENVIRONMENT_STORAGE) as EnvironmentIds;
    if (Object.values(EnvironmentIds).includes(id)) {
      environment = environments[id];
    }
  }
})();
