import React from 'react';
import logo from 'assets/images/logo.svg';
import { Link } from 'gatsby';
import { routes } from 'routes';

export const Logo = () => (
  <Link to={routes.home.path}>
    <img className="nav-logo" src={logo} alt="Лого UpSchool" />
  </Link>
);
