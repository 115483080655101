import React from 'react';
import youtubeImg from 'assets/images/socials/youtube.svg';
import { SOCIAL_LINKS } from 'constants/contacts';

export const YoutubeLinks = () => (
  <div className="youtube-links">
    <div className="title">
      Наши <b>YouTube</b> каналы:
    </div>
    <a
      href={SOCIAL_LINKS.programmingYoutube}
      className="youtube-link"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img src={youtubeImg} alt="Youtube логотип" />
      <span>по программированию</span>
    </a>
    <a
      href={SOCIAL_LINKS.chessYoutube}
      className="youtube-link"
      target="_blank"
      rel="noreferrer noopener"
    >
      <img src={youtubeImg} alt="Youtube логотип" />
      <span>по шахматам</span>
    </a>
  </div>
);
