import React from 'react';
import { DEFAULT_PRICE, PRICE_LIST } from 'constants/prices';
import { IPrice } from 'types/price';
import { LocationContext } from 'containers/Location/LocationContext';

let price: IPrice;

export const usePrice = (): IPrice => {
  const location = React.useContext(LocationContext);

  if (price) {
    return price;
  }

  if (location.isReal) {
    const foundPrice = PRICE_LIST.find((priceList) =>
      priceList.countries.includes(location.countryCode),
    );

    if (foundPrice) {
      price = foundPrice;
      return foundPrice;
    }
  }

  return DEFAULT_PRICE;
};
