import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Это обязательное поле',
  },
  string: {
    email: 'Введите настоящий email',
  },
});
